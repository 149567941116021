import React from "react"
import Layout from "../components/layouts/investments_layout"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Meta from "../components/meta"


const Thanks = ({ data }) => (
    <Layout>
        <Meta title="Thanks" description="Contact Form Success" />
        <Container>
            <Row>
                <Col className="align-self-center text-center">
                    <h1>Thank you!</h1>
                    <h2>Thanks for getting in touch.</h2>
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default Thanks;